.data-grid-container {
    width: 100% !important;
    height: 90vh !important;
}

.MuiDataGrid-main {
    padding-left: 15px;
}

.MuiDataGrid-columnHeadersInner {
    padding-left: 10px;
}

.css-18lolh3-MuiDataGrid-root {
    height: 100% !important;
}

.MuiDataGrid-columnHeadersInner {
    background-color: lightyellow;
}

.MuiSvgIcon-root {
    path {
        color: black;
    }
}

.MuiDataGrid-columnHeadersInner {
    padding-right: 10px;
}

.MuiSvgIcon-fontSizeSmall path {
    fill: #1976d2;
}

.create-button-container {
    position: fixed;
    bottom: 15px;
    left: 15px;
}

