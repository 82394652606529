#specialty-nationality-container {
    transform: translateY(-100px);
}

#specialty-container {
    transform: translateY(-100px);
}

@media(max-width: 1200px) {
    #specialty-nationality-container {
        transform: translateY(-85px);
    }
}