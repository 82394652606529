.details-heading {
  padding-top: 30px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 40px;
  transform: translateX(-30px);
}

.details-container {
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  justify-content: space-around;

  .details-box {
    width: 25%;

    #grid-container {
      margin-bottom: 10px;
    }

    .box-item {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 50px;
      background-color: #ffffff;
      border-radius: 5px;
    }
  }
}

.secondary {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  color: rgba(0, 0, 0, 0.6);
}

.locations-container {
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  .locations-heading {
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 40px;
  }

  .locations-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    justify-content: space-evenly;

    .location-card {
      margin: 5px 15px 15px 15px;

      .mb-30 {
        margin-bottom: 30px;
      }

      .mb-50 {
        margin-bottom: 50px;
      }

      .mt-5 {
        margin-top: 5px;
      }

      .mt-0 {
        margin-top: 0px !important;
      }

      .center {
        align-items: center;
        justify-content: center;
        align-self: center;
      }
      .line-wrapper {
        display: flex;
        flex-direction: row;

        .line {
          margin-top: 10px;
          margin-right: 10px;
        }
      }
    }
  }

  .location-actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

.admin-coach-container {
  justify-content: space-evenly;
}

.all-users {
  justify-content: space-evenly;
}

.centered-content-container {
  display: flex;
  margin-top: 40px;
  margin-bottom: 40px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transform: translateX(-30px);
}

#session-time {
  display: block;
  text-align: center;
  height: fit-content;
  min-height: 50px;
}

@media (max-width: 1200px) {
  .details-heading {
    font-size: medium;
  }

  .box-item {
    height: 40px !important;
    font-size: small;
  }
}

.label-input-field {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}