.categories-container {
    margin-top: 30px;
}

#sub-category {
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: large;
    color: rgba($color: #000000, $alpha: 0.5);
    cursor: pointer;
}

#sub-category:hover {
    background-color: azure;
}

.MuiDataGrid-cell {
    max-width: fit-content;
}