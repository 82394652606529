.loading-spinner {
	position: fixed;
	top: 50%;
	transform: translateY(-50%);
	left: 50%;
	transform: translateX(-50%);
	width: 3rem;
	height: 3rem;
	pointer-events: none;
	z-index: 9999;
}

#spinner-wrapper{
    width: 100%;
    height: 100%;
	border: 3px solid rgba($color: #ffffff, $alpha: 0.3);
	border-radius: 50%;
	border-top-color: #1976d2;;
	border-right-color: #1976d2;
	pointer-events: none;
	animation: 0.7s spin infinite ease-in-out;
	// display: none;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }

}