.delete-dialog-container {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 16px;
    padding-right: 16px;
}

.delete-dialog-actions-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}