.reports-revenue-container {
    margin-top: 150px;
    display: flex;
    justify-content: center;
}

.reports-revenue-heading {
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
    font-size: large;
    text-transform: uppercase;
}

#reports-revenue-box {
    margin-right: 50px;
    transform: translateY(40px);
}

#reports-revenue-element {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}


#item-reports-revenue {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 30px;
    font-size: large;
}

.reports-revenue-input-container {
    display: flex;
    justify-content: space-around;
    padding: 20px;
    border: 2px solid black;
    border-radius: 5px;
    width: 500px;
    height: 400px;
}

.reports-revenue-box-two {
    display: flex;
    flex-direction: column;
    justify-content:center;
    transform: translateY(-30px);
    
}


.input-container {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
}

.input-fields {
    padding: 5px;
    width: 200px;
    height: 32px;
    border: none;
    border-radius: 4px;
    font-size: large;

    &--edit {
        @extend .input-fields;
        font-size: medium;
        text-align: center;
    }
}

input[type="text"]:disabled{background-color:white;}
input[type="date"]:disabled{background-color:white;}

.input-fields:-webkit-autofill,
.input-fields:-webkit-autofill:hover,
.input-fields:-webkit-autofill:focus,
.input-fields:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #000 !important;
}



#result-container {
    transform: translateY(50px);
}

#result-wrapper {
    transform: translateY(120px);
}

#result-box {
    transform: translateY(155px);
}

.filter-result {
    margin: 0;
    width: 210px;
    height: 40px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1976d2;
    color: white;
    font-size: large;
}

.filter-button-container {
    margin-top: 20px;
}

select {
    width: 210px;
    height: 40px !important;
    font-size: 1rem !important;
    font-weight: 100;
}


@media  (max-height: 850px) {
    .reports-revenue-container {
        margin-top: 100px;
    }
    .reports-revenue-heading {
        top: 80px;
        font-size: medium;
    }
}

@media  (max-width: 1200px) {
    .reports-revenue-input-container {
        width: 400px;
        height: 400px;
    }

    #item-reports-revenue {
        width: 100px;
        height: 20px;
        font-size: small;
    }

    .input-fields {
        padding: 5px;
        width: 100px;
        height: 30px;
        font-size: small;
    }

    .filter-result {
        width: 110px;
        height: 38px;
        font-size: medium;
    }

    .reports-revenue-box-two {
        transform: translateY(0px);
        
    }

    label {
        font-size: small;
    }

    select {
        width: 110px !important;
        height: 38px !important;
        font-size: small !important;
        font-weight: 100;
    }

    #result-container {
        transform: translateY(50px);
    }
}

