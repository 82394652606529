@media  (max-width: 1200px) {
    #specialty-container {
        transform: translateY(-135px);
    }

    #result-box {
        transform: translateY(100px) !important;
    }

    #result-wrapper {
        transform: translateY(70px) !important;
    }
}