
#logo {
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
}

.move-logo {
    transform: translateX(220px) !important;
}

.MuiButtonBase-root {
    .MuiSvgIcon-root {
        path {
            color: white;
        }
    }
}

#active-panel {
    margin-left: 10px;
}


