.revenue-container {
    display: flex;
    justify-content: space-around;
    padding: 20px;
    border: 2px solid black;
    border-radius: 5px;
    width: 800px;
    height: 400px;
}

#revenue-box {
    margin-right: 50px;
}

@media  (max-width: 1200px) {
    .revenue-container {
        width: 400px;
        height: 300px;
    }
}
