.slider-container {
    position: absolute;
    top: 0%;
    width: fit-content;
    height: 99.5vh;
    border-top: 1px solid rgba($color: #000000, $alpha: 0.1);
    border-right: 1px solid rgba($color: #000000, $alpha: 0.1);
    z-index: 100;
    background: white;
    transition: 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    left: -273px;
    overflow-y: hidden;
}

.open{
    left: 0;
}

.category-container {
    padding-right: 10px;
}

.slider-closer {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: end;
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
}

.slider-category {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    
}

.slider-category:hover {
    background-color: lightgray;
}

.category-title {
    transform: translateY(-2px);
}