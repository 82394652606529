.action-blocking-overlay {
    width: 100vw;
    height: 100vh;
    background-color: rgba($color: #000000, $alpha: 0.5);
    position: absolute;
    top: 0;
    left: 0;
}

.input-container label {
    margin-bottom: 5px;
}

.delete-gym-confirm-dialog-container {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 16px;
    padding-right: 16px;
}

.delete-gym-confirmation-dialog-actions-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}